import "../App.css";
import {
  addDays,
  addHours,
  addMinutes,
  differenceInMinutes,
  format,
  isAfter,
  isBefore,
} from "date-fns";
import { useEffect, useState } from "react";
import _, { difference } from "lodash";
import { Actions } from "../store/order/action";
import { connect } from "react-redux";
import AWSAppSyncClient from "aws-appsync";
import { gql } from "@apollo/client";
import OrderPopup from "./OrderPopup";
import OrderEdit from "./OrderEdit";
// HACK since this PR {@link https://github.com/awslabs/aws-mobile-appsync-sdk-js/pull/633/files} has not been accepted
// after several months, we go ahead and modify the method to drop the graphql_headers that are causing subscription problems.
const {
  AppSyncRealTimeSubscriptionHandshakeLink,
} = require("aws-appsync-subscription-link/lib/realtime-subscription-handshake-link");
const oldStartSubscription =
  AppSyncRealTimeSubscriptionHandshakeLink.prototype
    ._startSubscriptionWithAWSAppSyncRealTime;
AppSyncRealTimeSubscriptionHandshakeLink.prototype._startSubscriptionWithAWSAppSyncRealTime =
  function (a) {
    if (a.options) {
      delete a.options.graphql_headers;
    }
    return oldStartSubscription.call(this, a);
  };
const client = new AWSAppSyncClient({
  url: "https://4ds2hrf7znerlparbqgw7doqce.appsync-api.eu-west-1.amazonaws.com/graphql",
  region: "eu-west-1",
  auth: {
    type: "AWS_IAM",
    credentials: {
      region: "eu-west-1",
      accessKeyId: "AKIASRSLUTOSFYHOGBLQ",
      secretAccessKey: "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",
    },
  },
  disableOffline: true,
});

const onWoltDrive = `subscription onWoltDriveOrders($endpoint: String){
  onWoltDriveOrders(endpoint: $endpoint){
    _deleted
    _version
    createdAt
    customerSupport
    dropoff
    endpoint
    id
    woltOrderReferenceID
    trackingReference
    orderMerchantReferenceID
    parcels
    pickup
    orderObject
    type
    pickupTime
    price
    shipmentPromiseID
    recipient
    status
    updatedAt
    }
}`;

function Orders({
  integration,
  getIntegration,
  order,
  getOrders,
  updateOrder,
  resendOrder,
  setAsManuallyFixed,
}) {
  const [orders, setOrders] = useState(_.sortBy(order, (o) => o.pickupTime));
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogOpen1, setDialogOpen1] = useState(false);
  const [dialogData, setDialogData] = useState(null);
  const [rejectedCount, setRejectedCount] = useState(0);
  const [failedCount, setFailedCount] = useState(0);
  const integrationID = window.location.pathname.split("/")[1];
  console.log(integrationID);
  useEffect(() => {
    getOrders(integrationID);
    getIntegration(integrationID);
  }, []);

  useEffect(() => {
    let temp = order || orders;
    temp = _.sortBy(temp, (o) => o.pickupTime);
    setOrders(temp);
  }, [order]);

  //create subscription
  useEffect(() => {
    let subscription;
    if (integrationID) {
      (async () => {
        console.log("subscripting");

        subscription = client
          .subscribe({
            query: gql(onWoltDrive),
            variables: { endpoint: integrationID },
          })
          .subscribe({
            next: (data) => {
              console.log(data, "subscription data");
              const order = data.data.onWoltDriveOrders;
              if (order.endpoint == integrationID)
                updateOrder(data.data.onWoltDriveOrders);
            },
            error: (error) => console.log(error, "error"),
          });
      })();
    }
    return () => {
      console.log("unsubscribe");
      subscription && subscription.unsubscribe();
    };
  }, [integrationID]);

  const capitalize = (name) => {
    if (!name) return;

    let split = name.split(" ");
    for (let i = 0; i < split.length; i++) {
      split[i] = split[i][0]?.toUpperCase() + split[i].substr(1);
    }
    let cap = split.join(" ");
    return cap;
  };

  const orderStatus = (type) => {
    let status = "";
    switch (type) {
      case "Shipment Promise":
        status = "Order sent to wolt Drive";
        break;
      case "Received":
        status = "Order Accepted";
        break;
      case false:
        status = "Pickup estimated time updated";
        break;
      case "Rejected":
        status = "Order Rejected";
        break;
      case "Pickedup":
        status = "Order Pickedup from Restaurant";
        break;
      case "Delivered":
        status = "Order Delivered";
        break;
      case "Customer no show":
        status = "Customer no show";
        break;
      case "INFO_RECEIVED":
        status = "Wolt drive received order details";
        break;
      default:
        status = type;
        console.log(type);
        break;
    }
    return status;
  };
  // const showAlert = (orderId) => {
  //   window.alert(
  //     `wolt order ${orderId} has been rejected. Please contact wolt drive and retry.`
  //   );
  // };

  useEffect(() => {
    if (orders) {
      let count = 0;
      let failed = 0;
      orders.map((o) => {
        const time =
          (integration?.settings &&
            JSON.parse(integration?.settings)?.openingTime) ||
          "00:00";
        const splitTime = time.split(":");
        let nextOpening = new Date().setHours(splitTime[0], splitTime[1]);
        let prevOpening = new Date().setHours(splitTime[0], splitTime[1]);
        let lastOpeningTime = new Date().setHours(splitTime[0], splitTime[1]);
        if (isAfter(lastOpeningTime, new Date())) {
          prevOpening = addDays(new Date(), -1).setHours(
            splitTime[0],
            splitTime[1]
          );
        } else {
          nextOpening = addDays(new Date(), 1).setHours(
            splitTime[0],
            splitTime[1]
          );
        }
        const pickupTime =
          o.pickupTime.length < 10
            ? addMinutes(new Date(), o.pickupTime)
            : o.pickupTime;
        const prevHourBefore = addHours(prevOpening, -1);
        const hourBefore = addHours(nextOpening, -1);
        const newDayOpened =
          isAfter(new Date(), nextOpening) &&
          isAfter(new Date(pickupTime), hourBefore);
        const prevDayOpened =
          !isAfter(new Date(), nextOpening) &&
          isAfter(new Date(pickupTime), prevHourBefore);
        if (
          (integration?.settings &&
            JSON.parse(integration?.settings)?.openingTime &&
            (newDayOpened || prevDayOpened)) ||
          !(
            integration?.settings &&
            JSON.parse(integration?.settings)?.openingTime
          )
        ) {
          if (
            o.status == "Rejected" &&
            ((isBefore(
              new Date(o.pickupTime),
              new Date(addMinutes(new Date(), 30))
            ) &&
              isAfter(new Date(o.pickupTime), new Date())) ||
              isAfter(new Date(o.pickupTime), new Date()))
          ) {
            count = count + 1;
            console.log(o);
            // showAlert(o.woltOrderReferenceID);
          }
          if (
            ((o.status == "Shipment Promise" &&
              Math.abs(
                differenceInMinutes(new Date(o.createdAt), new Date())
              ) >= 1) ||
              o.status == "Unable to send order to Wolt Drive") &&
            o.pickupTime &&
            isAfter(new Date(o.pickupTime), addHours(new Date(), -2))
          )
            failed = failed + 1;
        }
      });
      setRejectedCount(count);
      setFailedCount(failed);
    }
  }, [orders]);
  return (
    <div className="">
      <OrderPopup o={dialogData} open={dialogOpen} setOpen={setDialogOpen} />
      <OrderEdit
        o={dialogData}
        open={dialogOpen1}
        setOpen={setDialogOpen1}
        resendOrder={resendOrder}
        integration={integration}
      />
      <div style={{ padding: 30 }}>
        {failedCount > 0 && (
          <div
            style={{
              backgroundColor: "rgb(255 83 83 / 19%)",
              padding: 5,
              margin: 5,
            }}
          >
            <h3>
              {failedCount} orders failed while sending to wolt drive. Please
              retry.
            </h3>
          </div>
        )}
        {rejectedCount > 0 && (
          <div style={{ backgroundColor: "#ff00004f", padding: 5, margin: 5 }}>
            <h3>
              {rejectedCount} orders got rejected from wolt drive. Please
              contact wolt drive and retry.
            </h3>
          </div>
        )}
        <h2>Current Orders</h2>
        <div className="grid">
          {orders &&
            orders.length > 0 &&
            orders.map((o) => {
              const trackingLink = o.trackingReference?.includes("https://")
                ? o.trackingReference
                : `https://track.wolt.com/track/${o.trackingReference}`;
              const dropoff = JSON.parse(o.dropoff);
              console.log(
                (isBefore(
                  new Date(o.pickupTime),
                  new Date(addMinutes(new Date(), 30))
                ) &&
                  isAfter(new Date(o.pickupTime), new Date())) ||
                  isAfter(new Date(o.pickupTime), new Date()),
                o.status,
                o.pickupTime,
                o.id
              );
              const time =
                (integration?.settings &&
                  JSON.parse(integration?.settings)?.openingTime) ||
                "00:00";
              const splitTime = time.split(":");
              let nextOpening = new Date().setHours(splitTime[0], splitTime[1]);
              let prevOpening = new Date().setHours(splitTime[0], splitTime[1]);
              let lastOpeningTime = new Date().setHours(
                splitTime[0],
                splitTime[1]
              );
              if (isAfter(lastOpeningTime, new Date())) {
                prevOpening = addDays(new Date(), -1).setHours(
                  splitTime[0],
                  splitTime[1]
                );
              } else {
                nextOpening = addDays(new Date(), 1).setHours(
                  splitTime[0],
                  splitTime[1]
                );
              }
              const pickupTime =
                o.pickupTime.length < 10
                  ? addMinutes(new Date(), o.pickupTime)
                  : o.pickupTime;
              const prevHourBefore = addHours(prevOpening, -1);
              const hourBefore = addHours(nextOpening, -1);
              const newDayOpened =
                isAfter(new Date(), nextOpening) &&
                isAfter(new Date(pickupTime), hourBefore);
              const prevDayOpened =
                !isAfter(new Date(), nextOpening) &&
                isAfter(new Date(pickupTime), prevHourBefore);
              if (
                (integration?.settings &&
                  JSON.parse(integration?.settings)?.openingTime &&
                  (newDayOpened || prevDayOpened)) ||
                !(
                  integration?.settings &&
                  JSON.parse(integration?.settings)?.openingTime
                )
              )
                if (
                  !(
                    o.status == "Pickedup" ||
                    o.status == "Delivered" ||
                    o.status == "Testing integration" ||
                    o.status == "Customer no show" ||
                    o.status == "Manually Fixed Issue" ||
                    o.status.includes("error")
                  )
                )
                  if (
                    o.pickupTime == "null" ||
                    (o.status == "Rejected" &&
                      ((isBefore(
                        new Date(o.pickupTime),
                        new Date(addMinutes(new Date(), 30))
                      ) &&
                        isAfter(new Date(o.pickupTime), new Date())) ||
                        isAfter(new Date(o.pickupTime), new Date()))) ||
                    (o.status.includes("error") &&
                      o.pickupTime != "null" &&
                      ((isBefore(
                        new Date(o.pickupTime),
                        new Date(addMinutes(new Date(), 30))
                      ) &&
                        isAfter(new Date(o.pickupTime), new Date())) ||
                        isAfter(new Date(o.pickupTime), new Date()))) ||
                    o.status != "Rejected"
                  ) {
                    console.log(
                      o.pickupTime == "null",
                      o.pickupTime &&
                        isAfter(
                          new Date(o.pickupTime),
                          addHours(new Date(), -2)
                        ),
                      o.status == "Rejected" &&
                        ((isBefore(
                          new Date(o.pickupTime),
                          new Date(addMinutes(new Date(), 30))
                        ) &&
                          isAfter(new Date(o.pickupTime), new Date())) ||
                          isAfter(new Date(o.pickupTime), new Date())),
                      o.status.includes("error") &&
                        o.pickupTime != "null" &&
                        ((isBefore(
                          new Date(o.pickupTime),
                          new Date(addMinutes(new Date(), 30))
                        ) &&
                          isAfter(new Date(o.pickupTime), new Date())) ||
                          isAfter(new Date(o.pickupTime), new Date())),
                      o.status != "Rejected",
                      "test"
                    );
                    if (
                      o.pickupTime &&
                      isAfter(new Date(o.pickupTime), addHours(new Date(), -2))
                    )
                      return (
                        <div
                          className="order"
                          onClick={() => {
                            setDialogData(o);
                            setDialogOpen(true);
                          }}
                          style={{
                            backgroundColor:
                              o.status == "Rejected"
                                ? "#ff00004f"
                                : (o.status == "Shipment Promise" &&
                                    Math.abs(
                                      differenceInMinutes(
                                        new Date(o.createdAt),
                                        new Date()
                                      )
                                    ) >= 1) ||
                                  o.status ==
                                    "Unable to send order to Wolt Drive"
                                ? "rgb(255 83 83 / 19%)"
                                : "white",
                          }}
                        >
                          <h4>
                            Wolt Order ID: <b>{o.woltOrderReferenceID}</b>
                          </h4>
                          <h5>
                            Order ID:
                            {o &&
                              o.orderObject &&
                              JSON.parse(o && o.orderObject)?.id}
                          </h5>

                          <p>
                            {o.status == "Shipment Promise" &&
                            Math.abs(
                              differenceInMinutes(
                                new Date(o.createdAt),
                                new Date()
                              )
                            ) >= 1 ? (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <b
                                  onClick={(e) => {
                                    resendOrder(o.orderObject, o);
                                    e.stopPropagation();
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <u>An error occured while sending order.</u>
                                </b>
                                <button
                                  onClick={(e) => {
                                    resendOrder(o.orderObject, o);
                                    e.stopPropagation();
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  Resend order to Wolt Drive
                                </button>
                              </div>
                            ) : o.status == "Rejected" ? (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <b
                                  onClick={(e) => {
                                    resendOrder(o.orderObject, o);
                                    e.stopPropagation();
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <u>Order Rejected</u>
                                </b>
                                <button
                                  onClick={(e) => {
                                    resendOrder(o.orderObject, o);
                                    e.stopPropagation();
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  Resend order to Wolt Drive
                                </button>
                              </div>
                            ) : o.status ==
                              "Unable to send order to Wolt Drive" ? (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <b>
                                  <u>
                                    Wolt drive status: {orderStatus(o.status)}.
                                  </u>
                                </b>
                                <button
                                  onClick={(e) => {
                                    resendOrder(o.orderObject, o);
                                    e.stopPropagation();
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  Resend order to Wolt Drive
                                </button>

                                <button
                                  onClick={(e) => {
                                    setAsManuallyFixed(o.id, o._version);
                                    e.stopPropagation();
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  Click here to set order as manually fixed with
                                  wolt drive
                                </button>
                              </div>
                            ) : (
                              <b>Wolt drive status: {orderStatus(o.status)}</b>
                            )}
                          </p>
                          {(o.status == "Rejected" ||
                            (o.status == "Shipment Promise" &&
                              Math.abs(
                                differenceInMinutes(
                                  new Date(o.createdAt),
                                  new Date()
                                )
                              ) >= 1) ||
                            o.status ==
                              "Unable to send order to Wolt Drive") && (
                            <button
                              onClick={(e) => {
                                setDialogData(o);
                                setDialogOpen1(true);
                                e.stopPropagation();
                              }}
                            >
                              Edit order details and resend order
                            </button>
                          )}
                          <p>
                            Estimated pickup time:{" "}
                            {o.pickupTime != "null" &&
                              (!o.pickupTime.includes("T")
                                ? format(
                                    new Date(
                                      addMinutes(new Date(), o.pickupTime)
                                    ),
                                    "dd MMM yyyy HH:mm"
                                  )
                                : format(
                                    new Date(o.pickupTime),
                                    "dd MMM yyyy HH:mm"
                                  ))}
                          </p>
                          <p>
                            {console.log(dropoff)}
                            Estimated dropoff:{" "}
                            {dropoff?.scheduledTime
                              ? format(
                                  new Date(dropoff?.scheduledTime),
                                  "dd MMM yyyy HH:mm"
                                )
                              : dropoff?.etaMinutes &&
                                o?.pickupTime &&
                                o?.pickupTime != "null" &&
                                !dropoff?.etaMinutes?.includes("T")
                              ? format(
                                  new Date(
                                    addMinutes(
                                      new Date(o?.pickupTime),
                                      dropoff?.etaMinutes
                                    )
                                  ),
                                  "dd MMM yyyy HH:mm"
                                )
                              : dropoff?.etaMinutes == 0
                              ? format(new Date(), "dd MMM yyyy HH:mm")
                              : format(
                                  new Date(dropoff?.etaMinutes),
                                  "dd MMM yyyy HH:mm"
                                )}
                          </p>
                          <p>
                            Name:{" "}
                            {capitalize(
                              o &&
                                o.orderObject &&
                                JSON.parse(o && o.orderObject)
                                  ?.client_first_name
                            ) +
                              " " +
                              capitalize(
                                o &&
                                  o.orderObject &&
                                  JSON.parse(o && o.orderObject)
                                    ?.client_last_name
                              )}
                          </p>
                          <p>Contact Number: {JSON.parse(o.recipient).phone}</p>
                          <p>
                            Delivery Fee:{" "}
                            {JSON.parse(o.price).amount
                              ? "€" +
                                (JSON.parse(o.price).amount / 100).toFixed(2)
                              : "Not Available"}
                          </p>
                          <a
                            onClick={(e) => e.stopPropagation()}
                            href={trackingLink}
                          >
                            Tracking Url
                          </a>
                        </div>
                      );
                  }
            })}
        </div>
      </div>
      <div style={{ marginTop: 40 }}>
        <h2>Past Orders</h2>
        <div className="grid">
          {_.sortBy(orders, (o) => o.pickupTime)
            .reverse()
            .map((o) => {
              const time =
                (integration?.settings &&
                  JSON.parse(integration?.settings)?.openingTime) ||
                "00:00";
              const splitTime = time.split(":");
              let nextOpening = new Date().setHours(splitTime[0], splitTime[1]);
              let prevOpening = new Date().setHours(splitTime[0], splitTime[1]);
              let lastOpeningTime = new Date().setHours(
                splitTime[0],
                splitTime[1]
              );
              if (isAfter(lastOpeningTime, new Date())) {
                prevOpening = addDays(new Date(), -1).setHours(
                  splitTime[0],
                  splitTime[1]
                );
              } else {
                nextOpening = addDays(new Date(), 1).setHours(
                  splitTime[0],
                  splitTime[1]
                );
              }
              const pickupTime =
                o.pickupTime.length < 10
                  ? addMinutes(new Date(), o.pickupTime)
                  : o.pickupTime;
              const prevHourBefore = addHours(prevOpening, -1);
              const hourBefore = addHours(nextOpening, -1);
              const newDayOpened =
                isAfter(new Date(), nextOpening) &&
                isAfter(new Date(pickupTime), hourBefore);
              const prevDayOpened =
                !isAfter(new Date(), nextOpening) &&
                isAfter(new Date(pickupTime), prevHourBefore);
              if (
                ((integration?.settings &&
                  JSON.parse(integration?.settings)?.openingTime &&
                  (newDayOpened || prevDayOpened)) ||
                  !(
                    integration?.settings &&
                    JSON.parse(integration?.settings)?.openingTime
                  )) &&
                (o.status == "Pickedup" ||
                  o.status == "Testing integration" ||
                  o.status == "Delivered" ||
                  o.status == "Customer no show" ||
                  o.status.includes("error") ||
                  o.status == "Manually Fixed Issue" ||
                  (o.status == "Rejected" &&
                    !(
                      (isBefore(
                        new Date(o.pickupTime),
                        new Date(addMinutes(new Date(), 30))
                      ) &&
                        isAfter(new Date(o.pickupTime), new Date())) ||
                      isAfter(new Date(o.pickupTime), new Date())
                    )))
              ) {
                const trackingLink = o.trackingReference?.includes("https://")
                  ? o.trackingReference
                  : `https://track.wolt.com/track/${o.trackingReference}`;
                const dropoff = JSON.parse(o.dropoff);
                console.log(dropoff);
                return (
                  <div
                    className="order"
                    style={{
                      backgroundColor:
                        o.status == "Rejected" ? "#ff00004f" : "white",
                    }}
                    onClick={() => {
                      setDialogData(o);
                      setDialogOpen(true);
                    }}
                  >
                    <h4>
                      Wolt Order ID: <b>{o.woltOrderReferenceID}</b>
                    </h4>
                    <h5>
                      Order ID:
                      {o && o.orderObject && JSON.parse(o && o.orderObject)?.id}
                    </h5>
                    <p>
                      <b>Wolt drive status: {orderStatus(o.status)}</b>
                    </p>
                    <p>
                      Estimated pickup time:{" "}
                      {o.pickupTime != "null" &&
                        format(
                          new Date(
                            o?.pickupTime?.length < 10
                              ? addMinutes(new Date(), o.pickupTime)
                              : o.pickupTime
                          ),
                          "dd MMM yyyy HH:mm"
                        )}
                    </p>
                    <p>
                      Estimated dropoff:{" "}
                      {dropoff?.scheduledTime
                        ? format(
                            new Date(dropoff?.scheduledTime),
                            "dd MMM yyyy HH:mm"
                          )
                        : dropoff?.etaMinutes &&
                          o?.pickupTime &&
                          typeof dropoff?.etaMinutes != "string"
                        ? format(
                            new Date(
                              addMinutes(
                                new Date(o?.pickupTime),
                                dropoff?.etaMinutes
                              )
                            ),
                            "dd MMM yyyy HH:mm"
                          )
                        : dropoff?.etaMinutes == 0
                        ? format(new Date(), "dd MMM yyyy HH:mm")
                        : format(
                            new Date(dropoff?.etaMinutes),
                            "dd MMM yyyy HH:mm"
                          )}
                    </p>
                    <p>
                      Name:{" "}
                      {capitalize(
                        o &&
                          o.orderObject &&
                          JSON.parse(o && o.orderObject)?.client_first_name
                      ) +
                        " " +
                        capitalize(
                          o &&
                            o.orderObject &&
                            JSON.parse(o && o.orderObject)?.client_last_name
                        )}
                    </p>
                    <p>Contact Number: {JSON.parse(o.recipient).phone}</p>
                    <p>
                      Delivery Fee:{" "}
                      {JSON.parse(o.price).amount
                        ? "€" + (JSON.parse(o.price).amount / 100).toFixed(2)
                        : "Not Available"}
                    </p>

                    <a onClick={(e) => e.stopPropagation()} href={trackingLink}>
                      Tracking Url
                    </a>
                  </div>
                );
              }
            })}
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    order: state.orders,
    integration: state.integration,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getOrders: (integrationId) => dispatch(Actions.getOrders(integrationId)),
  getIntegration: (integrationId) =>
    dispatch(Actions.getIntegration(integrationId)),
  updateOrder: (order) => dispatch(Actions.modifyOrder(order)),
  subscriptionOrders: (integrationId) =>
    dispatch(Actions.subscriptionOrders(integrationId)),
  resendOrder: (orderObject, existing) =>
    dispatch(Actions.resendOrder(orderObject, existing)),
  setAsManuallyFixed: (orderID, orderVersion) =>
    dispatch(Actions.setAsManuallyFixed(orderID, orderVersion)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
