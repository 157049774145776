import { API, graphqlOperation, Auth } from "aws-amplify";
import axios from "axios";
import { Signer } from "@aws-amplify/core";
import AWSAppSyncClient from "aws-appsync";
import { gql } from "@apollo/client";

const client = new AWSAppSyncClient({
  url: "https://4ds2hrf7znerlparbqgw7doqce.appsync-api.eu-west-1.amazonaws.com/graphql",
  region: "eu-west-1",
  auth: {
    type: "AWS_IAM",
    credentials: {
      region: "eu-west-1",
      accessKeyId: "AKIASRSLUTOSFYHOGBLQ",
      secretAccessKey: "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",
    },
  },
  disableOffline: true,
});
const getIntegrationSettings = `query getIntegrationSettings($id: ID!) {
  getIntegrationSettings(id: $id) {
    id
    name
    type
    businessID
    userID
    apps
    settings
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
  }
}`;

const Types = {
  ORDER_UPDATE_ORDERS: "ORDER_UPDATE_ORDERS",
  ORDER_SET_LOCATION: "ORDER_SET_LOCATION",
  ORDER_MODIFY_ORDERS: "ORDER_MODIFY_ORDERS",
  ORDER_UPDATE_INTEGRATION: "ORDER_UPDATE_INTEGRATION",
};

const setLocation = (location) => ({
  type: Types.ORDER_SET_LOCATION,
  location: location,
});
const updateOrders = (orders) => ({
  type: Types.ORDER_UPDATE_ORDERS,
  orders,
});

const updateIntegration = (integration) => ({
  type: Types.ORDER_UPDATE_INTEGRATION,
  integration,
});

const modifyOrder = (orders) => ({
  type: Types.ORDER_MODIFY_ORDERS,
  orders,
});

const integrationID = window.location.pathname.split("/")[1] || "0";

const axiosClient = axios.create({
  baseURL: "https://y7fhcse6h1.execute-api.eu-west-1.amazonaws.com/live",
});

const awsCreds = {
  region: "eu-west-1",
  accessKeyId: "AKIASRSLUTOSFYHOGBLQ",
  secretAccessKey: "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",
};

const createSignedRequest = (method, endpoint, data = "") => {
  const request = {
    method: method,
    url: endpoint,
    data: data,
    headers: {
      integration: integrationID,
      "Content-Type": "application/json",
    },
  };

  const accessInfo = {
    access_key: awsCreds.accessKeyId,
    secret_key: awsCreds.secretAccessKey,
  };

  const serviceInfo = {
    service: "execute-api",
    region: awsCreds.region,
  };

  const signedRequest = Signer.sign(request, accessInfo, serviceInfo);
  delete signedRequest.headers["host"]; //Remove host header
  return signedRequest;
};

const getIntegration = () => {
  return async (dispatch) => {
    let integ;
    (async () => {
      console.log("subscripting");

      integ = await client.query({
        query: gql(getIntegrationSettings),
        variables: { id: integrationID },
      });
      console.log(integ, "subscription data");
      dispatch(updateIntegration(integ.data.getIntegrationSettings));
    })();
  };
};
// axiosClient.interceptors.request.use(interceptor);

const getOrders = (endpoint) => {
  return async (dispatch) => {
    try {
      const response = await axiosClient(
        createSignedRequest(
          "GET",
          // "http://localhost:3000/wh/woltDriveOrders"
          "https://y7fhcse6h1.execute-api.eu-west-1.amazonaws.com/live/wh/woltDriveOrders"
          // JSON.stringify(params)
        )
      );
      console.log(response);
      dispatch(updateOrders(response.data.response));
    } catch (e) {
      console.log(e, "error");
    }
  };
};

const resendOrder = (orderObject, existing, integration) => {
  let endpoint;
  if (
    integrationID === "9b5e98b6-a330-466e-85e9-628de52529d5" ||
    !integrationID
  ) {
    endpoint = "gloriaFoodToWoltDrive";
  } else if (integration.type === "GloriaFoodWoltDrive") {
    endpoint = "gloriaFoodToWoltDriveClient";
  } else {
    endpoint = "foodAppToWoltDriveClient";
  }

  return async (dispatch) => {
    try {
      console.log(
        JSON.stringify({ orders: [JSON.parse(orderObject)], existing }),
        "sending data"
      );
      const response = await axiosClient(
        createSignedRequest(
          "POST",
          // "http://localhost:3000/wh/woltDriveOrders"
          "https://y7fhcse6h1.execute-api.eu-west-1.amazonaws.com/live/wh/" +
            endpoint,
          JSON.stringify({ orders: [JSON.parse(orderObject)], existing })
        )
      );
      console.log(response.data.response);
    } catch (e) {
      console.log(e, "error");
    }
  };
};

const setAsManuallyFixed = (integrationID, version) => {
  return async (dispatch) => {
    try {
      const response = await axiosClient(
        createSignedRequest(
          "POST",
          // "http://localhost:3000/wh/woltDriveOrders"
          "https://y7fhcse6h1.execute-api.eu-west-1.amazonaws.com/live/wh/gloriaFoodSetManuallyFixed",
          JSON.stringify({ integrationID, version })
        )
      );
      console.log(response.data.response);
    } catch (e) {
      console.log(e, "error");
    }
  };
};

const Actions = {
  setLocation,
  updateOrders,
  getOrders,
  modifyOrder,
  resendOrder,
  setAsManuallyFixed,
  getIntegration,
};

export { Types, Actions };
