import "../App.css";
import _ from "lodash";
import { connect } from "react-redux";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import React, { useEffect, useState } from "react";
import DateTimePicker from "react-datetime-picker";
import { addMinutes, differenceInMinutes, format, isBefore } from "date-fns";

function OrderEdit({ o, open, setOpen, resendOrder, integration }) {
  const [value, onChange] = useState(new Date());
  const [valued, onChanged] = useState(new Date());
  const [city, setCity] = useState();
  const [moreAddress, setmoreAddress] = useState();
  const [street, setStreet] = useState();

  const [errorStatus, setErrorStatus] = useState();
  const capitalize = (name) => {
    if (!name) return;
    let split = name.split(" ");
    for (let i = 0; i < split.length; i++) {
      split[i] = split[i][0]?.toUpperCase() + split[i].substr(1);
    }
    let cap = split.join(" ");
    return cap;
  };

  const resendButtonUpdates = () => {
    let newOrder = JSON.parse(o.orderObject);
    let vd = valued;
    let v = value;
    if (isBefore(new Date(vd), new Date(v))) {
      onChanged(v);
      vd = v;
      console.log(true);
    }
    console.log(valued, value, vd, v);
    if (vd && Math.abs(differenceInMinutes(new Date(), new Date(vd))) > 70) {
      newOrder.for_later = true;
      newOrder.fulfill_at = new Date(vd).toISOString();
    } else {
      newOrder.for_later = false;
      newOrder.fulfill_at = new Date(value).toISOString();
    }
    console.log(street, "test1");
    const previous = newOrder.client_address_parts;
    newOrder.client_address_parts = {
      street: street,
      city: city,
      more_address: moreAddress,
    };
    console.log(
      JSON.stringify(previous),
      JSON.stringify(newOrder.client_address_parts),
      _.isEqual(
        JSON.stringify(previous),
        JSON.stringify(newOrder.client_address_parts)
      )
    );
    if (
      _.isEqual(
        JSON.stringify(previous),
        JSON.stringify(newOrder.client_address_parts)
      )
    ) {
      newOrder.latitude = 0;
      newOrder.longitude = 0;
    }
    console.log(newOrder, "test1", JSON.stringify(newOrder));
    resendOrder(JSON.stringify(newOrder), o, integration);
    setOpen(false);
  };

  useEffect(() => {
    console.log(o, "test1");
    if (o && o.orderObject) {
      let orderDetails = JSON.parse(o.orderObject);

      if (o.errorStatus) {
        if (o.errorStatus == "INVALID_SCHEDULED_DROPOFF_TIME") {
          setErrorStatus(
            "The order was scheduled too early. Either send order for asap or select dropoff time at least an hour in the future"
          );
        } else if (o.errorStatus == "DROPOFF_OUTSIDE_OF_DELIVERY_AREA") {
          setErrorStatus(
            "The order delivery address is out of your Wolt Drive delivery area. Make sure the delivery address is correct."
          );
        } else if (o.errorStatus == "ShipementPromiseFailure") {
          setErrorStatus(
            "The order delivery failed while sending to Wolt Drive ."
          );
        }
      }
      const dropoff = JSON.parse(o.dropoff);
      const pickup =
        o.pickupTime != "null" &&
        (!o.pickupTime.includes("T")
          ? format(
              new Date(addMinutes(new Date(), o.pickupTime)),
              "dd MMM yyyy HH:mm"
            )
          : format(new Date(o.pickupTime), "dd MMM yyyy HH:mm"));
      console.log(
        new Date(pickup).getHours(),
        new Date(pickup).getMinutes(),
        new Date()
          .setHours(new Date(pickup).getHours(), new Date(pickup).getMinutes())
          .toString(),
        new Date(
          new Date().setHours(
            new Date(pickup).getHours(),
            new Date(pickup).getMinutes()
          )
        )
      );
      onChange(new Date(pickup));

      onChanged(
        new Date(
          dropoff?.scheduledTime
            ? format(new Date(dropoff?.scheduledTime), "dd MMM yyyy HH:mm")
            : dropoff?.etaMinutes &&
              o?.pickupTime &&
              o?.pickupTime != "null" &&
              !dropoff?.etaMinutes?.includes("T")
            ? format(
                new Date(
                  addMinutes(new Date(o?.pickupTime), dropoff?.etaMinutes)
                ),
                "dd MMM yyyy HH:mm"
              )
            : dropoff?.etaMinutes == 0
            ? format(new Date(), "dd MMM yyyy HH:mm")
            : format(new Date(dropoff?.etaMinutes), "dd MMM yyyy HH:mm")
        )
      );
      setCity(orderDetails?.client_address_parts.city);
      setStreet(orderDetails?.client_address_parts.street);
      setmoreAddress(orderDetails?.client_address_parts.more_address);
    }
  }, [o]);

  return (
    <div className="App">
      <Dialog open={open} onClose={() => setOpen(false)}>
        <div style={{ padding: 40 }}>
          {errorStatus && <p>{errorStatus}</p>}
          <DialogTitle>
            <h4>
              Wolt Order ID: <b>{o && o.woltOrderReferenceID}</b>
            </h4>
            <h5>Order ID: {JSON.parse(o && o.orderObject)?.id}</h5>
          </DialogTitle>
          <h5>
            Name:{" "}
            {capitalize(JSON.parse(o && o.orderObject)?.client_first_name) +
              " " +
              capitalize(JSON.parse(o && o.orderObject)?.client_last_name)}
          </h5>
          <h5 style={{ paddingTop: 10, paddingBottom: 5 }}>Items</h5>
          <div style={{ paddingLeft: 10 }}>
            {o &&
              o.parcels &&
              o.parcels.map((i) => {
                return <p> {i} </p>;
              })}
          </div>

          <h6>Pickup Time</h6>
          <DateTimePicker
            onChange={onChange}
            value={value}
            disableClock={true}
          />

          <button
            onClick={() => {
              onChange();
            }}
          >
            Clear Pickup
          </button>
          <br />

          <h6 style={{ paddingTop: 15 }}>Dropoff time</h6>
          <DateTimePicker
            onChange={onChanged}
            value={valued}
            disableClock={true}
          />
          <button
            onClick={() => {
              onChanged();
            }}
          >
            Clear Dropoff
          </button>
          <br />

          <h6 style={{ paddingTop: 15 }}>Client Address </h6>
          <span>Street:{"  "}</span>
          <input
            onChange={(e) => setStreet(e.target.value)}
            defaultValue={street}
          />
          <br />
          <span>City:{"  "}</span>
          <input
            onChange={(e) => setCity(e.target.value)}
            defaultValue={city}
          />
          <br />

          <span>More Address:{"  "}</span>
          <input
            onChange={(e) => setmoreAddress(e.target.value)}
            defaultValue={moreAddress}
          />
          <br />

          <button onClick={() => resendButtonUpdates()}>
            Resend order with update
          </button>
        </div>
      </Dialog>
    </div>
  );
}

export default connect()(OrderEdit);
