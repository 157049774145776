import { Types } from "./order/action";
import _ from "lodash";
import orderReducer from "./order/reducer";

const defaultState = {
  orders: [],
  businessLocation: null,
  integration: null,
};

const reducer = (state = defaultState, action) => {
  if (action && action.type.indexOf("ORDER") == 0) {
    return orderReducer(state, action);
  }
  return state;
};

export default reducer;
