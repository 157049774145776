import "./App.css";
import _ from "lodash";
import Orders from "./components/Orders";
import "bootstrap/dist/css/bootstrap.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/" exact>
            <Orders />
            {/* <div
              className="h-100"
              style={{
                padding: 30,
                justifyContent: "center",
                display: "flex",
                alignContent: "center",
                flexWrap: "wrap",
              }}
            >
              <h2>The url is incomplete. Please recheck the url entered.</h2>
            </div> */}
          </Route>
          <Route exact path="/:id">
            <Orders />
          </Route>
        </Switch>
        {/* <Orders /> */}
      </Router>
    </div>
  );
}

export default App;
