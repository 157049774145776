import "../App.css";
import _ from "lodash";
import { connect } from "react-redux";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";

function OrderPopup({ o, open, setOpen }) {
  const capitalize = (name) => {
    if (!name) return;
    let split = name.split(" ");
    for (let i = 0; i < split.length; i++) {
      split[i] = split[i][0]?.toUpperCase() + split[i].substr(1);
    }
    let cap = split.join(" ");
    return cap;
  };
  return (
    <div className="App">
      <Dialog open={open} onClose={() => setOpen(false)}>
        <div style={{ padding: 40 }}>
          <DialogTitle>
            <h4>
              Wolt Order ID: <b>{o && o.woltOrderReferenceID}</b>
            </h4>
            <h5>Order ID: {JSON.parse(o && o.orderObject)?.id}</h5>
          </DialogTitle>
          <h5>
            Name:{" "}
            {capitalize(JSON.parse(o && o.orderObject)?.client_first_name) +
              " " +
              capitalize(JSON.parse(o && o.orderObject)?.client_last_name)}
          </h5>
          <h5 style={{ paddingTop: 10, paddingBottom: 5 }}>Items</h5>
          <div style={{ paddingLeft: 10 }}>
            {o &&
              o.parcels &&
              o.parcels.map((i) => {
                return <p> {i} </p>;
              })}
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default connect()(OrderPopup);
