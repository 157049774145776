import { Types } from "./action";
import _ from "lodash";

const defaultState = {};
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case Types.ORDER_UPDATE_ORDERS: {
      let newState = _.cloneDeep(state);
      const orders = action.orders;
      newState.orders = orders;
      return newState;
    }
    case Types.ORDER_SET_LOCATION: {
      let newState = _.cloneDeep(state);
      newState.businessLocation = action.location;
      return newState;
    }
    case Types.ORDER_MODIFY_ORDERS: {
      let newState = _.cloneDeep(state);
      const newOrder = action.orders;
      const exists = newState.orders.findIndex((o) => o.id == newOrder.id);
      if (exists >= 0) {
        newState.orders[exists] = newOrder;
      } else newState.orders = [...newState.orders, newOrder];
      return newState;
    }
    case Types.ORDER_UPDATE_INTEGRATION: {
      let newState = _.cloneDeep(state);
      newState.integration = action.integration;
      return newState;
    }
    default:
      return state;
  }
};
export default reducer;
