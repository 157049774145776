import { createStore, applyMiddleware, compose } from "redux";

// Logger with default options
import logger from "redux-logger";
import thunk from "redux-thunk";

import reducer from "./reducer";

export default function configureStore2(initialState) {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(
    reducer,
    initialState,
    composeEnhancers(applyMiddleware(thunk, logger))
    // applyMiddleware(thunk,logger)
  );
  return store;
}
